import React from "react";
import ReactTable from "react-table";
import capitalize from "capitalize-pt-br";
import Loading from '../../../Components/Loading';
import MyAPI from "../../../../../connections/Api.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import Download from "../../../../../utils/ExportCSV.js";
import Notifications from "../../../Components/Toast.jsx";
import Card from "../../../../../components/Card/Card.jsx";
import Button from "../../../../../components/CustomButton/CustomButton.jsx";

import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { DatePikerConfig } from "../../../../../utils/Abstractions/Variables.jsx";
import { anyIso, isoShortDate } from "../../../../../utils/Abstractions/Functions.jsx";

import { ToastProvider } from "react-toast-notifications";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { printer } from "../../../../../utils/Relatorios.js";

import RamicashReceipt from './RamicashReceipt';

import { StatsCard } from '../../../../../components/StatsCard/StatsCard.jsx';

import * as S from './Styles';

export default function PIX({ goTo }) {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [selected, setSelect] = React.useState(['Ramicash']);
  const [isLoading, setLoading] = React.useState(false);

  const [finalDate, setFinalDate] = React.useState("");
  const [initialDate, setInitialDate] = React.useState("");

  const [ramicashReceipt, setRamicashReceipt] = React.useState("");
  const [showReceipt, setShowReceipt] = React.useState(false);

  const [novosPedidos, setNovosPedidos] = React.useState({qtd: 0, valor: 0});
  const [novosPrevisto, setNovosPrevisto] = React.useState({qtd: 0, valor: 0});
  const [recebidos, setRecebidos] = React.useState({qtd: 0, valor: 0});
  const [pendentes, setPendentes] = React.useState({qtd: 0, valor: 0});
  const [onSelectedSituacoes, setOnSelectedSituacoes] = React.useState(false);
  const [selectedSituacoes, setSelectSituacoes] = React.useState([]);

  const situacoes = [
    { name: "Aberta", code: "Aberta" },
    { name: "Adiantada", code: "Adiantada" },
    { name: "Cancelado", code: "Cancelado" },
    { name: "Fechado", code: "Fechado" },
    { name: "Pendente", code: "Pendente" },
    { name: "Rescisao", code: "Rescisao" }
  ];

  const fetchData = async () => {
    let errors = 0

    if (finalDate === '') {
      errors++
      setError([{ message: "Insira o período final!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (initialDate === '') {
      errors++
      setError([{ message: "Insira o período inicial!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    try {
      if (errors === 0) {
        setLoading(true)
        const response = await MyAPI.getVoucherList({ initialDate, finalDate, channels: ['PIX'], situacoes: selectedSituacoes.map(e => e.name) });
        
        setData(response.data)
        calculaResumos(response.data)
        setLoading(false)
      }
    } catch (e) {
      setError([{ message: "Não foi possível buscar os dados solicitados", appearance: "error", auto_dismiss: true }]);
    }
  }

  const calculaResumos = (pedidos) => {
    let novos = 0, novosValor = 0, novosPrevisto = 0, recebidos = 0, recebidosValor = 0, pendentes = 0, pendentesValor = 0;
    pedidos.filter((p) => (p.situacao !== 'Cancelado')).map((pedido) => {

      if ( pedido.parcela_atual === 1 ) {
        console.log({pedido});

        novos += 1;
        novosValor += pedido.valor;

        novosPrevisto += (((pedido.valor * Number(pedido.taxa_funcionario))) * pedido.parcelas);
      } else {
        if ( pedido.situacao !== 'Fechado' ) {
          recebidos += 1;
          recebidosValor += Number((pedido.valor * Number(pedido.taxa_funcionario)).toFixed(2));
        } else {
          pendentes += 1;
          pendentesValor += Number((pedido.valor * Number(pedido.taxa_funcionario)).toFixed(2));
        }
      }
    });

    setNovosPedidos({qtd: novos, valor: novosValor});
    setRecebidos({qtd: recebidos, valor: recebidosValor});
    setPendentes({qtd: pendentes, valor: pendentesValor});
    setNovosPrevisto({qtd: 0, valor: novosPrevisto});
  }

  const AdicionarTaxa = (valor, canal) => {
    if (canal === "Alimentação") {
      return (Number(valor * 0.05) + Number(valor))
        .toFixed(2)
        .replace(".", ",");
    } else if (canal === "Combustível") {
      return (Number(valor * 0.05) + Number(valor))
        .toFixed(2)
        .replace(".", ",");
    }
  };

  async function ReceiptCancel(id) {
    try {
      const answer = window.prompt(`Por qual motivo deseja cancelar esta guia?`)

      if (answer !== null) {
        if (answer.length > 0) {
          await MyAPI.getReceiptCancel(id, localStorage.getItem('Name'), `${answer}`);

          setError([{ message: "Guia cancelada com sucesso!", appearance: "success", auto_dismiss: true }]);

          setTimeout(() => window.location.reload(), 2000);
        } else {
          setError([{ message: "Insira um motivo para o cancelamento", appearance: "error", auto_dismiss: true }]);
        }
      }
    } catch (e) {
      setError([{ message: "Não foi possível excluir esta guia", appearance: "error", auto_dismiss: true }]);
    }
  }

  async function ShowReceipt(ramicash_id) {
    try {
      const { data } = await MyAPI.getRamicashReceipt(ramicash_id);
      setRamicashReceipt("");

      if ( data.file ) {
        setRamicashReceipt(data.file);
        setShowReceipt(true);
      } else {
        throw 'Anexo não gravado';
      }
    } catch (e) {
      setError([{ message: "Recibo do PIX não gravado na base.", appearance: "error", auto_dismiss: true }]);
    }
  }

  return (
    <div className="content" style={{ marginTop: 25 }}>      
      <RamicashReceipt show={showReceipt} closeModal={() => setShowReceipt(false)} data={ramicashReceipt} />
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo("Menu")}
                    style={{ backgroundColor: "#008000", color: "#FFF" }}
                    bsStyle="success"
                    simple={true}
                    type="button"
                    bsSize="sm"
                  >
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true" /> Menu
                  </Button>
                  <br />
                  <p align="center" style={{ fontSize: 20 }}>
                    PIX
                  </p>
                  <div style={{ marginTop: "10px" }}> </div>
                </>
              }
              content={
                <>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período inicial
                        </ControlLabel>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          value={anyIso(initialDate, "dd/MM/yyyy")}
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) =>
                            setInitialDate(isoShortDate(e, "dd/MM/yyyy"))
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período Final
                        </ControlLabel>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          value={anyIso(finalDate, "dd/MM/yyyy")}
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) =>
                            setFinalDate(isoShortDate(e, "dd/MM/yyyy"))
                          }
                        />
                      </FormGroup>
                    </Col>                    

                    <Col md={3}>
                      <S.WarapperButtons>
                        <Button
                          onClick={() => fetchData()}
                          style={{
                            backgroundColor: "#008000",
                            color: "#FFF",
                            fontSize: 16,
                            width: 116,
                            marginRight: 15
                          }}
                          bsStyle="success"
                          simple
                          type="button"
                          bsSize="sm"
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Pesquisar
                        </Button>
                        {data.length > 0 ? <Download files={data} /> : null}
                      </S.WarapperButtons>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
      {isLoading ?
        <Loading Height='400px' />
        :
        <Grid fluid>
          <Row>
            <Col md={4}>
              <StatsCard
                isSelected={false}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Valor Emprestado'
                statsValue={`R$ ${novosPedidos.valor.toFixed(2)}`}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText={`Em ${novosPedidos.qtd} novos pedidos no período`}
              />
            </Col>

            <Col md={4}>
              <StatsCard
                isSelected={false}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Receita'
                statsValue={`R$ ${ (novosPrevisto.valor - novosPedidos.valor).toFixed(2)}`}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Receita prevista'
              />
            </Col>

            <Col md={4}>
              <StatsCard
                isSelected={false}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Total'
                statsValue={`R$ ${novosPrevisto.valor.toFixed(2)}`}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText=''
              />
            </Col>          
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <div align="center" style={{ margin: 15 }}>
                      <ReactTable
                        data={data.map((prop, key) => {
                          return {
                            id: key,
                            credenciado: `${prop.credenciado_id
                              ? prop.credenciado_id.nome_fantasia.toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            empresa: `${prop.empresa_responsavel_id
                              ? prop.empresa_responsavel_id.nome_fantasia.toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            funcionario: `${prop.usuario_id
                              ? capitalize(prop.usuario_id.nome).toUpperCase()
                              : "* REMOVIDO *"
                              }`,
                            situacao: `${prop.situacao.toUpperCase()}`,
                            confirmacao: `${prop.horario_confirmacao}`,
                            canal: `${prop.canal}`,
                            valor: `${prop.valor
                              ? `R$ ${prop.canal === "Alimentação" ||
                                prop.canal === "Combustível"
                                ? AdicionarTaxa(
                                  prop.taxa_funcionario
                                    ? (
                                      Number(prop.taxa_funcionario) *
                                      prop.valor
                                    ).toFixed(2)
                                    : (
                                      prop.valor / prop.parcelas
                                    ).toFixed(2),
                                  prop.canal
                                )
                                : prop.taxa_funcionario
                                  ? (
                                    Number(prop.taxa_funcionario) *
                                    prop.valor
                                  )
                                    .toFixed(2)
                                    .replace(".", ",")
                                  : (prop.valor / prop.parcelas)
                                    .toFixed(2)
                                    .replace(".", ",")
                              }`
                              : `R$ 0,00`
                              }`,
                            parcela: `${prop.parcela_atual}/${prop.parcelas}`,
                            acoes: (
                              <div className="actions-center">
                                { prop.canal === 'Ramicash' ? 
                                <OverlayTrigger placement="top" overlay={<Tooltip id="cancel_tooltip">Comprovante PIX</Tooltip>}>
                                  <Button
                                    onClick={() => ShowReceipt(prop.ramicash_id)}
                                    bsStyle="success"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <i
                                      className="fa fa-file-image-o"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </OverlayTrigger>                                
                                : <></> }
                                <OverlayTrigger placement="top" overlay={<Tooltip id="remove_tooltip">Extrato Completo</Tooltip>}>
                                  <Button
                                    onClick={() => {
                                      printer(
                                        prop._id,
                                        prop.canal,
                                        prop.horario_confirmacao,
                                        prop.valor,
                                        prop.credenciado_id ? prop.credenciado_id.nome_fantasia.split(' ').filter(e => e !== ' ').length > 0 ? prop.credenciado_id.nome_fantasia : prop.credenciado_id.razao_social : '',
                                        "Ramicard",
                                        prop.empresa_responsavel_id?.nome_fantasia || '',
                                        prop.usuario_id.nome,
                                        prop.parcelas,
                                        prop.observacao,
                                        false,
                                        false,
                                        prop.taxa_funcionario
                                      )
                                    }
                                    }
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <i
                                      className="fa fa-file-text-o"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="cancel_tooltip">Cancelar</Tooltip>}>
                                  <Button
                                    onClick={() => ReceiptCancel(prop._id)}
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <i
                                      className="fa fa-ban"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </OverlayTrigger>                                
                              </div>
                            ),
                          };
                        })}
                        filterable
                        columns={[
                          {
                            Header: "Credenciado",
                            accessor: "credenciado",
                          },
                          {
                            Header: "Empresa",
                            accessor: "empresa",
                          },
                          {
                            Header: "Funcionário",
                            accessor: "funcionario",
                          },
                          {
                            Header: "Situação",
                            accessor: "situacao",
                          },
                          {
                            Header: "Confirmação",
                            accessor: "confirmacao",
                          },
                          {
                            Header: "Canal",
                            accessor: "canal",
                          },
                          {
                            Header: "Valor",
                            accessor: "valor",
                          },
                          {
                            Header: "Parcela",
                            accessor: "parcela",
                          },
                          {
                            Header: "",
                            accessor: "acoes",
                            filterable: false,
                            sortable: false,
                          },
                        ]}
                        showPaginationBottom={false}
                        defaultPageSize={data.length <= 10 ? 10 : 50}
                        showPaginationTop={true}
                        className="-striped -highlight text-center"
                      />
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      }
    </div>
  );
}
