import Canais from '../views/Administrativos/Pages/Channels/Index.jsx';
import Empresas from '../views/Administrativos/Pages/Companies/Index.jsx';
import Funcionarios from '../views/Administrativos/Pages/Employee/Index.jsx';
import Credenciados from '../views/Administrativos/Pages/Accredited/Index.jsx';
import Dashboard from '../views/Administrativos/Pages/Dashborad/Index.jsx';
import Relatorios from '../views/Administrativos/Pages/Information/Index.jsx';

import Charges from '../views/Administrativos/Pages/Charges/Index.jsx';
import News from '../views/Administrativos/Pages/News/Index';
import Simulator from '../views/Administrativos/Pages/Simulator/Index.jsx';

import Layers from '../views/Administrativos/Pages/Layers/Index.jsx';

import Correcao from '../views/Administrativos/Pages/Correcao/Index.jsx';

const Routes = [
  {
    path: '/dashboard',
    name: 'Painel de controle',
    icon: 'pe-7s-display1',
    component: Dashboard,
    layout: '/administrativo',
  },
  {
    name: 'Credenciados',
    path: '/credenciados',
    icon: 'pe-7s-portfolio',
    component: Credenciados,
    layout: '/administrativo',
  },
  {
    path: '/empresas',
    name: 'Empresas',
    icon: 'pe-7s-users',
    component: Empresas,
    layout: '/administrativo',
  },
  {
    path: '/funcionarios',
    name: 'Funcionarios',
    icon: 'pe-7s-id',
    component: Funcionarios,
    layout: '/administrativo',
  },
  {
    path: '/canais',
    name: 'Canais',
    icon: 'pe-7s-cart',
    component: Canais,
    layout: '/administrativo',
  },
  {
    path: '/faixas',
    name: 'Faixas Parcelas',
    icon: 'pe-7s-shuffle',
    component: Layers,
    layout: '/administrativo',
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    icon: 'pe-7s-graph1',
    component: Relatorios,
    layout: '/administrativo',
  },
  {
    path: '/cobrancas',
    name: 'Cobranças',
    icon: 'pe-7s-graph3',
    component: Charges,
    layout: '/administrativo',
  },
  {
    path: '/simulador',
    name: 'Simulador Financeiro',
    icon: 'pe-7s-calculator',
    component: Simulator,
    layout: '/administrativo',
  },
  {
    path: '/correcao',
    name: 'Correção em Massa',
    icon: 'pe-7s-magic-wand',
    component: Correcao,
    layout: '/administrativo',
  },
  {
    path: '/novidades',
    name: 'Versão 2.1.0',
    icon: 'fa fa-question-circle-o',
    component: News,
    layout: '/administrativo',
    upgrade: 'active-pro',
  }
];

export default Routes;
